.icon-arrow {
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  background: transparent;
}
.icon-arrow--right {
  border-top: 2px solid black;
  border-right: 2px solid black;
}
.icon-arrow--left {
  border-bottom: 2px solid black;
  border-left: 2px solid black;
}
.icon-arrow--top {
  border-top: 2px solid black;
  border-left: 2px solid black;
}
.icon-arrow--bottom {
  border-bottom: 2px solid black;
  border-right: 2px solid black;
}
.icon-arrow--button {
  cursor: pointer;
}
