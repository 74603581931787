.listing-table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
}
@media (max-width: 768px) {
  .listing-table {
    display: block;
  }
  .listing-table tbody {
    display: block;
    width: 100%;
  }
}
.listing-table__header-row {
  border-bottom: 1px solid #f3f0f0;
}
@media (max-width: 768px) {
  .listing-table__header-row {
    display: none;
  }
}
.listing-table__content-row {
  min-height: 80px;
  font-family: 'GEFlow', sans-serif;
}
@media (max-width: 768px) {
  .listing-table__content-row {
    display: grid;
    grid-template-columns: auto 50px;
    grid-auto-flow: column;
    height: auto;
    width: 100%;
    padding: 24px 8px 24px;
  }
}
.listing-table__header-cell {
  height: 80px;
  padding: 12px 4px;
  font-size: 20px;
  font-weight: 500;
}
@media (max-width: 1024px) {
  .listing-table__header-cell {
    font-size: 18px;
  }
}
.listing-table__content-cell {
  font-size: 16px;
  line-height: 24px;
  padding: 8px 4px;
}
@media (max-width: 1024px) {
  .listing-table__content-cell {
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 768px) {
  .listing-table__content-cell {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .listing-table__content-cell:not(.listing-table__content-cell--labeled) {
    display: block;
  }
  .listing-table__content-cell--labeled {
    display: flex;
  }
  .listing-table__content-cell--labeled::before {
    content: attr(data-label);
    margin-left: 16px;
    font-weight: 700;
    width: 80px;
  }
  .listing-table__content-cell--aside {
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .listing-table__content-cell--aside > * {
    margin-right: auto;
  }
}
@media (max-width: 768px) {
  .listing-table__content-wrapper--title {
    font-size: 18px;
    line-height: 26px;
    font-weight: bold;
  }
}
.listing-table__content-wrapper-date {
  font-family: 'Overpass', sans-serif;
}
.listing-table__content-wrapper-image img,
.listing-table__content-wrapper-image object {
  display: block;
  width: 110px;
}
@media (max-width: 1024px) {
  .listing-table__content-wrapper-image img,
  .listing-table__content-wrapper-image object {
    width: 100px;
  }
}
@media (max-width: 768px) {
  .listing-table__content-wrapper-image img,
  .listing-table__content-wrapper-image object {
    width: min(100%, 300px);
  }
}
.listing-table__content-wrapper-actions {
  display: flex;
}
.listing-table__content-wrapper-actions .listing-table__action-button {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.listing-table__content-wrapper-actions .listing-table__action-button:nth-of-type(n + 2) {
  margin-right: 8px;
}
@media (max-width: 768px) {
  .listing-table__content-wrapper-actions .listing-table__action-button:nth-of-type(n + 2) {
    margin-top: 8px;
    margin-right: auto;
  }
}
.listing-table__content-wrapper-actions .listing-table__action-button svg {
  font-size: 18px;
}
@media (max-width: 768px) {
  .listing-table__content-wrapper-actions .listing-table__action-button svg {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .listing-table__content-wrapper-actions .listing-table__action-button {
    margin-right: auto;
    width: 24px;
    height: 24px;
  }
}
@media (max-width: 768px) {
  .listing-table__content-wrapper-actions {
    display: block;
  }
}
@media (max-width: 768px) {
  .listing-table__checkbox .checkbox__label::before {
    right: auto;
    left: 0;
  }
  .listing-table__checkbox .checkbox__label::after {
    right: auto;
    left: 5px;
  }
}
.listing-table__spinner-contianer {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  padding: 80px 0px;
  display: flex;
  justify-content: space-around;
}
.listing-table__spinner {
  position: sticky;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 768px) {
  .listing-table__spinner {
    font-size: 14px;
  }
}
