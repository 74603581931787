.page-details__container {
  padding: 8px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 8px;
  position: relative;
}
.page-details__description-card {
  grid-column: 1 / 3;
}
.page-details__info-card {
  grid-column: 1 / 3;
}
.page-details__spinner-overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  padding: 200px 0px;
  display: flex;
  justify-content: space-around;
  z-index: 1;
}
.page-details__description-editor .q-editor-container .q-editor-editable {
  min-height: 300px;
}
