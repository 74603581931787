.inquiry-details__container {
  padding: 8px;
  gap: 8px;
  position: relative;
}
.inquiry-details__spinner-overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  padding: 200px 0px;
  display: flex;
  justify-content: space-around;
  z-index: 1;
}
.inquiry-details__message-field {
  grid-column: 1 / span 2;
}
