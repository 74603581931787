.pagination {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.pagination__navigation {
  display: flex;
  align-items: center;
}
.pagination__navigation-page {
  font-size: 18px;
  margin: 0px 4px;
}
