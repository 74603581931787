.private-container {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 80px 140px calc(100% - 220px);
}
@media (max-width: 768px) {
  .private-container {
    display: block;
  }
}
.private-container__overlay {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(255, 255, 255, 0.4);
  z-index: 4;
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s 0s;
  display: none;
}
@media (max-width: 768px) {
  .private-container__overlay {
    display: block;
  }
  .private-container__overlay--visible {
    transition: opacity 0.5s;
    opacity: 1;
    pointer-events: fill;
    visibility: visible;
  }
}
.side-panel {
  grid-column-start: 1;
  grid-column-end: 3;
  background: #1e1f20;
  padding-bottom: 16px;
  position: relative;
  z-index: 6;
}
.side-panel__wrapper {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  min-height: 100vh;
  padding-top: 72px;
}
.side-panel__logo {
  width: calc(100% - 40px);
  margin-right: 20px;
  display: block;
  width: 140px;
  cursor: pointer;
}
.side-panel__logo img {
  width: 100%;
  pointer-events: none;
}
.side-panel__logo--collapsed {
  width: 40px;
  margin: auto;
}
@media (max-width: 1200px) {
  .side-panel__logo {
    width: 40px;
    margin: auto;
  }
}
@media (max-width: 768px) {
  .side-panel__logo {
    width: 140px;
    margin-left: auto;
    margin-right: 20px;
  }
}
.side-panel__logo-image-mini {
  display: none;
}
@media (max-width: 1200px) {
  .side-panel__logo-image-mini {
    display: block;
  }
}
@media (max-width: 768px) {
  .side-panel__logo-image-mini {
    display: none;
  }
}
.side-panel__logo-image-large {
  display: block;
}
@media (max-width: 1200px) {
  .side-panel__logo-image-large {
    display: none;
  }
}
@media (max-width: 768px) {
  .side-panel__logo-image-large {
    display: block;
  }
}
.side-panel__navigation {
  margin-top: 60px;
  width: 100%;
}
.side-panel__navigation-item {
  text-decoration: none;
  cursor: pointer;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: white;
  font-family: 'GEFlow', sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  width: 100%;
  padding: 25px 20px 23px;
}
.side-panel__navigation-item--active {
  color: #e51c32;
  cursor: initial;
  pointer-events: none;
}
@media (pointer: fine) {
  .side-panel__navigation-item:not(.side-panel__navigation-item--active):hover {
    background-color: rgba(207, 255, 255, 0.1);
    color: #e51c32;
  }
}
.side-panel__navigation-item svg {
  margin-left: 20px;
}
.side-panel__toggle {
  text-decoration: none;
  cursor: pointer;
  color: white;
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 3.6rem !important;
}
@media (max-width: 1200px) {
  .side-panel__toggle {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  .side-panel--collapsed {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .side-panel--collapsed .side-panel__navigation-item svg {
    font-size: 2.5rem;
    margin: auto;
  }
  .side-panel--collapsed .side-panel__navigation-item-label {
    display: none;
  }
  .side-panel--collapsed .side-panel__logo-image-mini {
    display: block;
  }
  .side-panel--collapsed .side-panel__logo-image-large {
    display: none;
  }
}
@media (max-width: 1200px) and (min-width: 769px) {
  .side-panel {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .side-panel .side-panel__navigation-item svg {
    font-size: 2.5rem;
    margin: auto;
  }
  .side-panel .side-panel__navigation-item-label {
    display: none;
  }
}
@media (max-width: 768px) {
  .side-panel {
    position: fixed;
    top: 0;
    min-height: 100vh;
    width: min(100%, 320px);
    transform: translateX(100%);
    transition: transform 0.5s;
  }
  .side-panel--open {
    transform: translateX(0);
  }
}
.main-section {
  grid-column-start: 3;
  grid-column-end: 4;
  z-index: 2;
}
@media (max-width: 1200px) {
  .main-section {
    grid-column-start: 2;
    grid-column-end: 4;
  }
}
@media (max-width: 768px) {
  .main-section {
    width: 100%;
  }
}
.main-section--expanded {
  grid-column-start: 2;
  grid-column-end: 4;
}
.main-section--fixed {
  position: fixed;
}
.main-section:not(.main-section--fixed) {
  top: auto !important;
}
/* modifiers & hovers */
/* media queries */
