.card {
  background-color: white;
  padding: 30px 20px;
  box-shadow: 0 0 16px 3px rgba(50, 51, 53, 0.1);
  margin: 8px;
  border-radius: 10px;
}
.card__title {
  font-size: 36px;
  display: block;
}
.card--no-gap {
  margin: 0px;
}
