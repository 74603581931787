.auth-layout {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  padding: 56px;
  overflow-y: auto;
  justify-content: space-around;
  align-items: center;
}
.auth-layout__card {
  padding: 40px 32px;
}
.auth-layout__logo {
  display: block;
  padding: 0px 0px 40px 0px;
  width: 148px;
  box-sizing: content-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  margin: auto;
}
