@font-face {
  font-family: 'GEFlow';
  src: url('../public/fonts/GEFlow.eot');
  src: url('../public/fonts/GEFlow.eot?#iefix') format('embedded-opentype'), url('../public/fonts/GEFlow.woff2') format('woff2'), url('../public/fonts/GEFlow.woff') format('woff'), url('../public/fonts/GEFlow.ttf') format('truetype'), url('../public/fonts/GEFlow.svg#GEFlow') format('svg');
  font-weight: normal;
  font-style: normal;
}
html {
  font-size: 10px;
  direction: rtl;
}
body {
  font-family: 'GEFlow', sans-serif, 'Overpass', sans-serif;
  background: #f3f0f0;
  text-align: right;
}
.main-container {
  width: 100%;
}
.main-container__session-loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: #eee;
}
