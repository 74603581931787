@media (max-width: 768px) {
  .mini-header-layout {
    padding-top: 40px;
  }
}
.mini-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
  height: 40px;
  background: white;
  border-bottom: 1px solid #dbd8d8;
  padding: 0 20px;
  font-weight: 500;
  z-index: 2;
}
@media (max-width: 768px) {
  .mini-header {
    position: fixed;
    top: 0;
  }
}
.mini-header__corner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}
.mini-header__logout {
  cursor: pointer;
  margin-left: 10px;
  border-left: 1px solid black;
  padding-left: 10px;
}
.mini-header__logged-user {
  font-weight: 700;
}
.mini-header__burger-menu-toggle {
  display: none;
  cursor: pointer;
}
@media (max-width: 768px) {
  .mini-header__burger-menu-toggle {
    display: block;
  }
}
.mini-header__burger-menu-toggle svg {
  font-size: 2.5rem;
}
