.dropdown-field {
  width: 100%;
}
.dropdown-field__label {
  font-weight: 500;
  font-size: 16px;
  display: block;
  margin-bottom: 4px;
  padding-left: 2px;
}
.dropdown-field__select-container {
  width: 100%;
  position: relative;
}
.dropdown-field input:-webkit-autofill,
.dropdown-field input:-webkit-autofill:hover,
.dropdown-field input:-webkit-autofill:focus,
.dropdown-field input:-webkit-autofill:active,
.dropdown-field select:-webkit-autofill,
.dropdown-field select:-webkit-autofill:hover,
.dropdown-field select:-webkit-autofill:focus,
.dropdown-field select:-webkit-autofill:active,
.dropdown-field textarea:-webkit-autofill,
.dropdown-field textarea:-webkit-autofill:hover,
.dropdown-field textarea:-webkit-autofill:focus,
.dropdown-field textarea:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f3f0f0 inset !important;
}
.dropdown-field__control {
  width: 100%;
  min-height: 48px !important;
  font-size: 16px;
  font-family: 'GEFlow', sans-serif;
  border-radius: 10px !important;
  background-color: #f3f0f0 !important;
  border: none !important;
  color: #1e1f20 !important;
}
.dropdown-field__select--error .dropdown-field__control {
  background-color: #fce8eb !important;
}
.dropdown-field__select--error .dropdown-field__control:-webkit-autofill,
.dropdown-field__select--error .dropdown-field__control:-webkit-autofill:hover,
.dropdown-field__select--error .dropdown-field__control:-webkit-autofill:focus,
.dropdown-field__select--error .dropdown-field__control:-webkit-autofill:active,
.dropdown-field__select--error .dropdown-field__control:-webkit-autofill,
.dropdown-field__select--error .dropdown-field__control:-webkit-autofill:hover,
.dropdown-field__select--error .dropdown-field__control:-webkit-autofill:focus,
.dropdown-field__select--error .dropdown-field__control:-webkit-autofill:active,
.dropdown-field__select--error .dropdown-field__control:-webkit-autofill,
.dropdown-field__select--error .dropdown-field__control:-webkit-autofill:hover,
.dropdown-field__select--error .dropdown-field__control:-webkit-autofill:focus,
.dropdown-field__select--error .dropdown-field__control:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fce8eb inset !important;
}
.dropdown-field__error-message {
  opacity: 0;
  color: #e51c32;
  position: absolute;
  left: 1px;
  bottom: calc(100% + 3px);
  font-size: 12px;
  font-weight: 500;
}
.dropdown-field__error-message--visible {
  opacity: 1;
}
.dropdown-field--disabled .dropdown-field__select {
  opacity: 0.7;
  pointer-events: none;
}
