.checkbox {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.checkbox__input {
  display: none;
}
.checkbox__input--regular:checked ~ .checkbox__label::after {
  background-color: #e51c32;
  left: 6.4px;
}
.checkbox__input--regular:checked ~ .checkbox__label.checkbox__label--reversed::after {
  left: auto;
  right: 41.6px;
}
.checkbox__input--compact:checked ~ .checkbox__label::before {
  background-color: #323335;
}
.checkbox__input--compact:checked ~ .checkbox__label::after {
  opacity: 1;
}
.checkbox__label {
  cursor: pointer;
  position: relative;
}
.checkbox__label--regular {
  padding-left: 88px;
  min-height: 40px;
  min-width: 150px;
  display: flex;
  align-items: center;
  font-size: 16px;
}
.checkbox__label--regular::before {
  content: '';
  width: 80px;
  height: 40px;
  position: absolute;
  left: 0;
  top: calc(50% - 20px);
  border-radius: 34px;
  background: #f3f0f0;
}
.checkbox__label--regular::after {
  content: '';
  height: 32px;
  width: 32px;
  background-color: #323335;
  position: absolute;
  top: calc(50% - 16px);
  border-radius: 100%;
  transition: 0.2s;
}
.checkbox__label--regular::after {
  left: 41.6px;
}
.checkbox__label--compact {
  padding-right: 24px;
  min-height: 12px;
  padding-top: 1px;
}
.checkbox__label--compact::before,
.checkbox__label--compact::after {
  width: 12px;
  height: 12px;
  position: absolute;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
}
.checkbox__label--compact::before {
  content: '';
  border: 2px solid #323335;
  right: 0;
  top: 0;
}
.checkbox__label--compact::after {
  content: '';
  z-index: 1;
  transform: rotate(45deg);
  height: 6px;
  width: 3px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  right: 5px;
  top: 3px;
  opacity: 0;
}
.checkbox__label--fill-width {
  width: 100%;
}
.checkbox__label--reversed.checkbox__label--regular {
  padding-left: 0px;
  padding-right: 88px;
}
.checkbox__label--reversed.checkbox__label--regular::before {
  left: auto;
  right: 0;
}
.checkbox__label--reversed.checkbox__label--regular::after {
  right: 6.4px;
  left: auto;
}
.checkbox__label--reversed.checkbox__label--compact {
  padding-right: 0px;
  padding-left: 24px;
}
.checkbox__label--reversed.checkbox__label--compact::before {
  right: auto;
  left: 0;
}
.checkbox__label--reversed.checkbox__label--compact::after {
  left: 5.12px;
  right: auto;
}
.checkbox--disabled {
  opacity: 0.7;
  pointer-events: none;
}
