.alert-container {
  grid-template-columns: 1fr auto 1fr;
  padding: 12px 24px 16px;
  position: fixed;
  top: -1px;
  min-width: 300px;
  max-width: 500px;
  transform: translate(50%, -100%);
  background-color: white;
  box-shadow: 0 0 16px 3px rgba(50, 51, 53, 0.1);
  border-radius: 10px;
  z-index: 10;
  transition: transform 0.5s;
}
.alert-container--open {
  transform: translate(50%, 16px);
}
.alert-container--success {
  border: 2px solid #198754;
}
.alert-container--error {
  border: 2px solid #ef7784;
}
.alert-container--warning {
  border: 2px solid #ff7900;
}
.alert-container--info {
  border: 2px solid #1e92f4;
}
.alert-container__title {
  font-size: 20px;
  font-weight: 500;
  display: block;
  padding-left: 16px;
}
.alert-container__title + .alert-container__description {
  padding-top: 8px;
}
.alert-container__description {
  font-size: 16px;
  display: block;
}
.alert-container__close {
  position: absolute;
  top: 16px;
  left: 14px;
  font-size: 16px;
}
.main-section .alert-container {
  right: calc(50% + 110px);
  max-width: calc(100% - 40px - 220px);
}
@media (min-width: 1025px) {
  .main-section--expanded .alert-container {
    right: calc(50% + 40px);
    max-width: calc(100% - 40px - 80px);
  }
}
@media (max-width: 1200px) and (min-width: 769px) {
  .main-section .alert-container {
    right: calc(50% + 40px);
    max-width: calc(100% - 40px - 80px);
  }
}
@media (max-width: 768px) {
  .main-section .alert-container {
    right: 50%;
    max-width: calc(100% - 40px);
  }
}
