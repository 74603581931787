.form-card__form {
  display: grid;
}
.form-card__form--col-1 {
  grid-template-columns: repeat(1, 1fr);
}
.form-card__form--col-2 {
  grid-template-columns: repeat(2, 1fr);
}
.form-card__form--col-3 {
  grid-template-columns: repeat(3, 1fr);
}
.form-card__form--col-4 {
  grid-template-columns: repeat(4, 1fr);
}
.form-card__form--col-gap-1 {
  column-gap: 8px;
}
.form-card__form--col-gap-2 {
  column-gap: 16px;
}
.form-card__form--col-gap-3 {
  column-gap: 24px;
}
.form-card__form--col-gap-4 {
  column-gap: 32px;
}
.form-card__form--row-gap-1 {
  row-gap: 8px;
}
.form-card__form--row-gap-2 {
  row-gap: 16px;
}
.form-card__form--row-gap-3 {
  row-gap: 24px;
}
.form-card__form--row-gap-4 {
  row-gap: 32px;
}
.form-card .card__title ~ .form-card__form {
  margin-top: 16px;
}
