.login-form {
  width: 400px;
  max-width: 100%;
}
.login-form__input-field:nth-of-type(n + 2) {
  margin-top: 24px;
}
.login-form__input-field.input-field__input {
  font-family: 'GEFlow', sans-serif;
}
.login-form__submit {
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
}
.login-form__submit--loading {
  opacity: 0;
  pointer-events: none;
}
.login-form__remember-me {
  margin-top: 16px;
}
.login-form__submit-container {
  max-width: 200px;
  margin-top: 40px;
  position: relative;
}
.login-form__spinner {
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
}
.login-form__spinner .spinner__extra-child,
.login-form__spinner::before,
.login-form__spinner::after {
  width: 12px;
  height: 12px;
}
.login-form__spinner--loading {
  opacity: 1;
}
.login-form__error-message {
  color: #e51c32;
  padding-top: 16px;
  display: block;
}
