.image-uploader {
  width: 100%;
}
.image-uploader__label {
  font-weight: 500;
  font-size: 16px;
  display: block;
  margin-bottom: 4px;
  padding-left: 2px;
}
.image-uploader__input {
  display: none;
}
.image-uploader__container {
  background-color: #f3f0f0;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  max-width: 330px;
  max-height: 210px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  aspect-ratio: 33 / 21;
  position: relative;
}
.image-uploader__image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-uploader__spinner {
  position: absolute;
  top: 0;
  right: 0;
}
.image-uploader__spinner--overlay {
  background: rgba(255, 255, 255, 0.5);
}
.image-uploader__explorer {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
}
.image-uploader__explorer--disabled {
  pointer-events: none;
}
.image-uploader__no-image-icon {
  font-size: 40px !important;
  pointer-events: none;
}
.image-uploader__actions {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  aspect-ratio: 33 / 13;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.image-uploader__action-button {
  width: 34px;
  height: 34px;
  margin: 16px;
}
.image-uploader__action-button:nth-of-type(n + 2) {
  margin-right: 0px;
}
.image-uploader__action-button svg {
  font-size: 20px;
}
