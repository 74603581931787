.page-header {
  min-height: 142px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 16px;
}
.page-header__title {
  font-size: 48px;
  font-weight: 600;
}
.page-header__content {
  display: flex;
  justify-content: space-between;
}
.page-header__button:nth-child(n + 2) {
  margin-right: 16px;
}
