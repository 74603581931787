.action-bar {
  display: flex;
  justify-content: space-around;
  grid-template-columns: 1fr auto 1fr;
  padding: 24px 24px 20px;
  position: fixed;
  bottom: -1px;
  width: 600px;
  transform: translate(50%, 100%);
  background-color: white;
  box-shadow: 0 0 16px 3px rgba(50, 51, 53, 0.1);
  border-radius: 10px;
  transition: transform 0.5s;
}
.action-bar--open {
  transform: translate(50%, -16px);
}
.action-bar__content {
  margin: auto;
}
.main-section .action-bar {
  right: calc(50% + 110px);
  max-width: calc(100% - 40px - 220px);
}
@media (min-width: 1025px) {
  .main-section--expanded .action-bar {
    right: calc(50% + 40px);
    max-width: calc(100% - 40px - 80px);
  }
}
@media (max-width: 1200px) and (min-width: 769px) {
  .main-section .action-bar {
    right: calc(50% + 40px);
    max-width: calc(100% - 40px - 80px);
  }
}
@media (max-width: 768px) {
  .main-section .action-bar {
    right: 50%;
    max-width: calc(100% - 40px);
  }
}
